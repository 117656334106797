import { graphql, useStaticQuery } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { isEqual, uniqWith } from "lodash"
import * as React from "react"
import Layout from "../components/Layout"
import MobileWrapper from "../components/MobileWrapper"
import { Content, H1, Link, P } from "../styles"

const RecursosPage = () => {
  const data = useStaticQuery(graphql`
    {
      allContentfulResource(sort: {fields: createdAt, order: DESC}) {
        edges {
          node {
            id: contentful_id
            createdAt
            title
            link
          }
        }
      }
    }
`);

  return (
    <Layout>
        <MobileWrapper>
          <StaticImage
            placeholder="blurred"
            src="../images/resources.png"
            alt="home image"
            style={{ maxWidth: '50%', height: 'auto', position: 'absolute', right: 96 }}
          />
        </MobileWrapper>
      <Content>
        <H1 animation>
          Recursos
        </H1>
        <P animation>
          Te regalo algunos recursos que te pueden servir para mejorar tu salud hormonal, espero que los aproveches!
        </P>
        {uniqWith(data?.allContentfulResource?.edges, isEqual)?.map((edge: any) => 
          <Link href={edge?.node?.link} target="_blank"><h2>→ {edge?.node?.title} 🔗</h2></Link>
        )}
      </Content>
    </Layout>
  )
}

export default RecursosPage
